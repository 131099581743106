import React from 'react';
import {motion} from 'framer-motion';

const UserTypeToggle = ({userType, setUserType}) => {
    return (
        <div className="w-full flex justify-center mb-8">
            <div className="relative w-72 h-12 rounded-xl bg-gray-100 dark:bg-gray-800 p-1">
                {/* Background Pill */}
                <motion.div
                    className={`absolute inset-y-1 w-[calc(50%-4px)] rounded-lg ${
                        userType === 'customer'
                            ? 'bg-gradient-to-r from-purple-600 to-pink-600 dark:from-purple-500 dark:to-pink-500'
                            : 'bg-gradient-to-r from-emerald-500 via-teal-500 to-cyan-500 dark:from-emerald-400 dark:via-teal-400 dark:to-cyan-400'
                    }`}
                    animate={{
                        x: userType === 'customer' ? '4px' : 'calc(100% + 4px)',
                    }}
                    initial={false}
                    transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 30,
                    }}
                />

                {/* Buttons Container */}
                <div className="relative flex w-full h-full">
                    <button
                        onClick={() => setUserType('customer')}
                        className={`flex-1 flex items-center justify-center text-sm font-medium rounded-lg transition-colors duration-200 z-10 ${
                            userType === 'customer'
                                ? 'text-white dark:text-gray-900'
                                : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'
                        }`}
                    >
                        Customer
                    </button>
                    <button
                        onClick={() => setUserType('shop-owner')}
                        className={`flex-1 flex items-center justify-center text-sm font-medium rounded-lg transition-colors duration-200 z-10 ${
                            userType === 'shop-owner'
                                ? 'text-white dark:text-gray-900'
                                : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'
                        }`}
                    >
                        Shop Owner
                    </button>
                </div>

                {/* Enhanced hover effect */}
                <div
                    className="absolute inset-0 rounded-xl transition-opacity duration-200 pointer-events-none"
                    style={{
                        background: `linear-gradient(120deg,
    ${userType === 'shop-owner'
                            ? 'rgba(16, 185, 129, 0.1), rgba(6, 182, 212, 0.05)'
                            : 'rgba(168, 85, 247, 0.1), rgba(236, 72, 153, 0.05)'
                        })`,
                        opacity: 0.7
                    }}
                />
            </div>
        </div>
    );
};

export default UserTypeToggle;
