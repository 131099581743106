import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, Store, Calendar, Clock, Users2 } from 'lucide-react';

const SuccessView = ({ shopData, onViewShop }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            const steps = document.querySelector('.creation-steps');
            const trial = document.querySelector('.trial-banner');
            if (steps) {
                steps.style.opacity = '0';
                setTimeout(() => steps.style.display = 'none', 300);
            }
            if (trial) {
                trial.style.opacity = '0';
                setTimeout(() => trial.style.display = 'none', 300);
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <motion.div
            className="max-w-lg mx-auto px-4 py-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <motion.div
                className="bg-gradient-to-br from-primary/10 to-primary/5 rounded-2xl p-6 md:p-8 shadow-xl"
                initial={{ y: 20 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.2, type: "spring" }}
            >
                <motion.div
                    className="w-20 h-20 mx-auto mb-6 rounded-full bg-primary flex items-center justify-center"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ delay: 0.4, type: "spring" }}
                >
                    <Store className="w-10 h-10 text-primary-content" />
                </motion.div>

                <motion.h1
                    className="text-2xl md:text-3xl font-bold text-center mb-8"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6 }}
                >
                    Your shop is ready!
                </motion.h1>

                <motion.div
                    className="space-y-4 mb-8"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.8 }}
                >
                    <StatsCard
                        icon={Calendar}
                        title="Business Hours"
                        value={`${Object.keys(shopData.availability).filter(day => shopData.availability[day]).length} days/week`}
                    />
                    <StatsCard
                        icon={Clock}
                        title="Services"
                        value={`${shopData.services.length} services added`}
                    />
                    <StatsCard
                        icon={Users2}
                        title="Team Size"
                        value={`${shopData.employees?.length || 0} members`}
                    />
                </motion.div>

                <motion.button
                    className="btn btn-primary w-full group"
                    onClick={onViewShop}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1 }}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                >
                    View Your Shop
                    <ChevronRight className="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform" />
                </motion.button>

                <motion.p
                    className="text-sm text-center mt-6 text-base-content/60"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.2 }}
                >
                    Share your unique link with customers to start accepting bookings
                </motion.p>
            </motion.div>
        </motion.div>
    );
};

const StatsCard = ({ icon: Icon, title, value }) => (
    <motion.div
        className="flex items-center p-4 bg-base-100 rounded-xl shadow-sm"
        whileHover={{ scale: 1.02 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
        <div className="w-10 h-10 rounded-lg bg-primary/10 flex items-center justify-center mr-4">
            <Icon className="w-5 h-5 text-primary" />
        </div>
        <div>
            <p className="text-sm font-medium text-base-content/60">{title}</p>
            <p className="text-lg font-semibold">{value}</p>
        </div>
    </motion.div>
);

export default SuccessView;